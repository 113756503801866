import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";

export default function useTable(props, context) {
  // Misc
  const { t } = useI18n();

  // Constants
  const actionOptions = [
    {
      text: t("app.delete"),
      value: "delete",
      click: () => {
        const ids = selected.value.map(x => x.id);
        context.emit("delete", ids);
      }
    }
  ];

  // Computed
  const headHeaders = computed(() => {
    if (!hasSelectedMultiple.value) {
      return props.headers;
    }

    return props.headers.filter(header => header.value !== "__ACTIONS");
  });

  const hasSelectedMultiple = computed(() => {
    return props.multiple && selected.value.length > 0;
  });

  const hasSelectedAll = computed(() => {
    return props.rows.every(row => {
      return selected.value.some(x => x.id === row.id);
    });
  });

  // Data
  const selected = ref([]);

  // Methods
  const selectAll = () => {
    if (hasSelectedAll.value) {
      selected.value = [];
      return;
    }

    selected.value = props.rows;
  };

  const select = item => {
    const index = selected.value.findIndex(x => x.id === item.id);

    if (index !== -1) {
      selected.value.splice(index, 1);
      return;
    }

    selected.value.push(item);
  };

  const hasItem = id => {
    return selected.value.some(x => x.id === id);
  };

  return {
    hasSelectedMultiple,
    hasSelectedAll,
    selected,
    hasItem,
    selectAll,
    select,
    headHeaders,
    actionOptions
  };
}
